import React from 'react';
import SwitchTextAGD from './SwitchTextAGD';
import { Typography } from '@mui/material';
import wzorscian from '../Images/wzorscian.jpeg';
import { Box } from '@mui/system';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function WymiarowanieMediow(props) {
  const handlePiekarnikBuildinChange = (e) => {
    props.onPiekarnikBuildinChange(e.target.value);
  }

  return(
    <React.Fragment>
      <Typography variant="body2" component={'span'}>
        Podaj wymiary sprzętu AGD.
      </Typography>
      <br/>
      <Typography variant="body2" component={'span'}>
        Przypadki niestandardowe opisz w punkcie 6 (Uwagi dodatkowe).
      </Typography>
      <br/>
      <img src={wzorscian} alt='sciany'/>  
      <br/>
      <SwitchTextAGD
        captionsl='Lodówka'
        onDChange={props.onLodowkaDChange} 
        D={props.lodowkaD}
        onWChange={props.onLodowkaWChange} 
        W={props.lodowkaW}
        onSChange={props.onLodowkaSChange} 
        S={props.lodowkaS}
      />  
      <SwitchTextAGD
        captionsl='Zmywarka'
        onDChange={props.onZmywarkaDChange} 
        D={props.zmywarkaD}
        onWChange={props.onZmywarkaWChange} 
        W={props.zmywarkaW}
        onSChange={props.onZmywarkaSChange} 
        S={props.zmywarkaS}
      />  
      
      <SwitchTextAGD
        captionsl='Płyta grzewcza'
        onDChange={props.onPlytaDChange} 
        D={props.plytaD}
        onWChange={props.onPlytaWChange} 
        W={props.plytaW}
        onSChange={props.onPlytaSChange} 
        S={props.plytaS}
      />  
      <SwitchTextAGD
        captionsl='Okap'
        onDChange={props.onOkapDChange} 
        D={props.okapD}
        onWChange={props.onOkapWChange} 
        W={props.okapW}
        onSChange={props.onOkapSChange} 
        S={props.okapS}
        onOkapFreehangChange={props.onOkapFreehangChange}
        okapFreehang={props.okapFreehang}
      />  
      <React.Fragment>
        <br/>
        <Box sx={{ minWidth: 120, maxWidth:300 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rodzaj piekarnika</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.piekarnikBuildin}
              label="Rodzaj piekarnika"
              onChange={handlePiekarnikBuildinChange}
            >
              <MenuItem value={0}></MenuItem>
              <MenuItem value={1}>Zabudowa</MenuItem>
              <MenuItem value={2}>Wolnostojący</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <br/>
      </React.Fragment>
    </React.Fragment>
  )  
}
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Switch from '@mui/material/Switch';
import { TextField, MenuItem, Select, InputLabel, FormControl, FormControlLabel } from "@mui/material";

export default function SwitchText(props){
  const [disabled, setDisabled] = useState(true);

  const handleSwitch = (prevState) => {
    setDisabled(!prevState);
  }

  const handleDChange = (e) => {
    props.onDChange(e.target.value);
  }

  const handleWChange = (e) => {
    props.onWChange(e.target.value);
  }

  const handleSChange = (e) => {
    props.onSChange(e.target.value);
  }

  const handleOkapFreehangChange = (e) => {
    props.onOkapFreehangChange(e.target.value);
  }

  useEffect(() => {
    if (props.D > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [props.D]);

  return(
    <Box sx={{ alignItems: 'end' }} className='outerboxsl'>
      <FormControlLabel
          control={
            <Switch className='inlinesl' checked={!disabled} onChange={() => handleSwitch(disabled, props.value)}/>
          }
          label={props.captionsl}
          sx={{ml:0}}
      />
      <br/>
      {(()=>{
        if(!disabled){
          return(
            <React.Fragment>
            {(()=>{
              if(props.captionsl!=='Płyta grzewcza') {
                return(
                  <React.Fragment>
                    <Box
                      className='inlinesl'
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '15ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField 
                        id="standard-basic" 
                        value={props.W} 
                        onChange={handleWChange} 
                        type='number' 
                        label='Wysokość [cm]'
                        variant="standard" 
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                      />
                    </Box>
                   </React.Fragment>
                )}
              })()} 
            <Box
              className='inlinesl'
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '15ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                id="standard-basic" 
                value={props.S} 
                onChange={handleSChange} 
                type='number' 
                label='Szerokość [cm]'
                variant="standard" 
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Box>
            <Box
              className='inlinesl'
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '15ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                id="standard-basic" 
                value={props.D} 
                onChange={handleDChange} 
                type='number' 
                label='Głębokość [cm]'
                variant="standard" 
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Box>
            {(()=>{
              if(props.captionsl==='Okap') {
                return(
                  <React.Fragment>
                  <br/>
                  <Box sx={{ minWidth: 120, maxWidth:300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Rodzaj okapu</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.okapFreehang}
                        label="Rodzaj okapu"
                        onChange={handleOkapFreehangChange}
                      >
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={1}>Wolnowiszący</MenuItem>
                        <MenuItem value={2}>Podszafkowy</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  </React.Fragment>
                )
              }
            })()}  
            </React.Fragment>
          )
        }
      })()}
    </Box>
  );
};
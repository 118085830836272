import React from 'react';
import SwitchTextWymiarowanie from './SwitchTextWymiarowanie';
import { Typography } from '@mui/material';
import oknaall from '../Images/oknaall.jpeg';

export default function WymiarowanieMediow(props) {

  return(
    <React.Fragment>
      <Typography variant="body2" component={'span'}>
        Podaj szczegóły istniejących mediów.
      </Typography>
      <br/>
      <Typography variant="body2" component={'span'}>
        Przypadki niestandardowe opisz w punkcie 6 (Uwagi dodatkowe).
      </Typography>
      <br/>
      <img src={oknaall} alt='sciany'/>  
      <br/>
      <SwitchTextWymiarowanie
        placeholdersl='Odległość [cm]' 
        captionsl='Woda użytkowa'
        onValueChange={props.onWodaUzytkowaOdlegloscChange} 
        value={props.wodaUzytkowaOdleglosc}
        onScianaChange={props.onWodaUzytkowaScianaChange}
        sciana={props.wodaUzytkowaSciana}
        onWysokoscChange={props.onWodaUzytkowaWysokoscChange}
        wysokosc={props.wodaUzytkowaWysokosc}
      />  
      <SwitchTextWymiarowanie
        placeholdersl='Odległość [cm]' 
        captionsl='Przyłącze kanalizacyjne'
        onValueChange={props.onPrzylaczeKanalizacyjneOdlegloscChange} 
        value={props.przylaczeKanalizacyjneOdleglosc}
        onScianaChange={props.onPrzylaczeKanalizacyjneScianaChange}
        sciana={props.przylaczeKanalizacyjneSciana}
        onWysokoscChange={props.onPrzylaczeKanalizacyjneWysokoscChange}
        wysokosc={props.przylaczeKanalizacyjneWysokosc}
      />  
      <SwitchTextWymiarowanie
        placeholdersl='Odległość [cm]' 
        captionsl='Przyłącze gazowe'
        onValueChange={props.onGazOdlegloscChange} 
        value={props.gazOdleglosc}
        onScianaChange={props.onGazScianaChange}
        sciana={props.gazSciana}
        onWysokoscChange={props.onGazWysokoscChange}
        wysokosc={props.gazWysokosc}
      />  
      <SwitchTextWymiarowanie
        placeholdersl='Odległość [cm]' 
        captionsl='Okap podłączony do wentylacji'
        onValueChange={props.onOkapOdlegloscChange} 
        value={props.okapOdleglosc}
        onScianaChange={props.onOkapScianaChange}
        sciana={props.okapSciana}
        onWysokoscChange={props.onOkapWysokoscChange}
        wysokosc={props.okapWysokosc}
      />  
    </React.Fragment>
  )  
}
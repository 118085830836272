import React from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useEffect, useState } from "react";

export const API_URL = "http://kuchnie.stolarz.usermd.net/api/kitchenmodels/";

export default function KitchenModelsList(props){
  const [kitchenmodels, setKitchenModels] = useState([])

  const handleChange = (e) => {
    props.onKitchenModelChange(e.target.value);
  };

  useEffect(() => {
    axios.get(API_URL)
      .then(res => {
        setKitchenModels(res.data);
      })
  },[]);

  return (
    <Box sx={{ minWidth: 120, maxWidth:300, mt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="select-model">Model</InputLabel>
        <Select
          id="select-model-id"
          value={props.kitchenModel}
          label="Model"
          onChange={handleChange}
        >
          <MenuItem value={''}></MenuItem>
          {kitchenmodels.map(kitchenmodel => 
            <MenuItem value={kitchenmodel.model_name}>{kitchenmodel.model_name}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}

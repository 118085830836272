import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { TextField } from "@mui/material";
import wzorscian from '../Images/wzorscian.jpeg';
import oknoa from '../Images/oknoa.jpeg'
import oknob from '../Images/oknob.jpeg'
import oknoc from '../Images/oknoc.jpeg'
import oknod from '../Images/oknod.jpeg'

export default function LokalizacjaOkna(props) {
  const [messageWindowLocation, setMessageWindowLocation] = React.useState('')
  const onValueChange = (e) => {
    props.onIfOknoChange(e.target.value);
  };
  const onWysokoscOknaOdPodlogiDoParapetuValueChange = (e) => {
    props.onWysokoscOknaOdPodlogiDoParapetuChange(e.target.value);
  };
  const onSzerokoscValueChange = (e) => {
    props.onSzerokoscOknaChange(e.target.value);
  };
  const onWysokoscValueChange = (e) => {
    props.onWysokoscOknaChange(e.target.value);
  };
  const onOdlegloscOknaOdKrawedziValueChange = (e) => {
    props.onOdlegloscOknaOdKrawedziChange(e.target.value);
  };
  const onLokalizacjaOknaValueChange = (e) => {
    props.onLokalizacjaOknaChange(e.target.value);
    if (e.target.value===1 ) {
      setMessageWindowLocation('Podaj odległość prawej krawędzi okna od ściany A.');
    } else if (e.target.value===2 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany A.');
    } else if (e.target.value===3 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany D.');
    } else if (e.target.value===4 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany D.');
    }
  };
  React.useEffect(()=>{
    if (props.lokalizacjaOkna===1 ) {
      setMessageWindowLocation('Podaj odległość prawej krawędzi okna od ściany A.');
    } else if (props.lokalizacjaOkna===2 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany A.');
    } else if (props.lokalizacjaOkna===3 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany D.');
    } else if (props.lokalizacjaOkna===4 ) {
      setMessageWindowLocation('Podaj odległość lewej krawędzi okna od ściany D.');
    }
  }, [props.lokalizacjaOkna]);

  return(
    <React.Fragment>
      <Typography variant="body2" component={'span'}>Czy na ścianie z meblami znajduje się okno?</Typography>  
      <br />
      <br />
      <Box sx={{ minWidth: 120, maxWidth:300 }}>
        <FormControl fullWidth>
          <InputLabel id="select-ifokno-label">Wybierz</InputLabel>
            <Select
              id="select-ifokno"
              value={props.ifOkno}
              label="Ustawienie"
              onChange={onValueChange}
            >
              <MenuItem value={0}>Nie</MenuItem>
              <MenuItem value={1}>Tak</MenuItem>
            </Select>
        </FormControl>  
      </Box>
      <br />
      <Box sx={{ minWidth: 120, maxWidth:300 }}>
        {(() => {
          if (props.ifOkno===1) {
            return (
              <React.Fragment>
              <Box
                className='inlinesl'
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField 
                  id="standard-basic" 
                  value={props.wysokoscOknaOdPodlogiDoParapetu} 
                  onChange={onWysokoscOknaOdPodlogiDoParapetuValueChange} 
                  type='number' 
                  label='Wysokość od podłogi do parapetu [cm]' 
                  variant="standard" 
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </Box>

              <Box
                className='inlinesl'
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField 
                  id="standard-basic" 
                  value={props.szerokoscOkna} 
                  onChange={onSzerokoscValueChange} 
                  type='number' 
                  label='Szerokość okna [cm]' 
                  variant="standard" 
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </Box>


              <Box
              className='inlinesl'
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
              >
                <TextField 
                  id="standard-basic" 
                  value={props.wysokoscOkna} 
                  onChange={onWysokoscValueChange} 
                  type='number' 
                  label='Wysokość okna [cm]' 
                  variant="standard" 
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </Box>
              <br />


              <Typography variant="body2" component={'span'}>
                Wybierz lokalizację okna (Wybierz ścianę).
              </Typography>  
              {(() => {
                if (props.lokalizacjaOkna===0){
                  return(
                    <img src={wzorscian} alt='sciany'/>
                  )
                }
              })()}


              <br />
              <br />
              <Box sx={{ minWidth: 120, maxWidth:300 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-lokalizacja-okna-label">Lokalizacja okna</InputLabel>
                    <Select
                      id="select-lokalizacja-okna"
                      value={props.lokalizacjaOkna}
                      label="Lokalizacja okna"
                      onChange={onLokalizacjaOknaValueChange}
                    >
                      <MenuItem value={0}></MenuItem>
                      <MenuItem value={3}>A</MenuItem>
                      <MenuItem value={2}>B</MenuItem>
                      <MenuItem value={4}>C</MenuItem>
                      <MenuItem value={1}>D</MenuItem>
                    </Select>
                </FormControl>  
              </Box>
              <br/>

            </React.Fragment>
            )
          }
        })()}   
      </Box>
      <Box sx={{ minWidth: 120, maxWidth:300 }}>
        {(() => {
          if (props.lokalizacjaOkna>0) {
              return (
                <React.Fragment>
                  <Typography variant="body2" component={'span'}>
                    {messageWindowLocation}
                  </Typography>  
                  {(() => {
                      switch(props.lokalizacjaOkna) {
                        case 1:
                          return <img src={oknod} alt='oknod'/>
                        case 2:
                          return <img src={oknob} alt='oknob'/>
                        case 3:
                          return <img src={oknoa} alt='oknoa'/>
                        case 4:
                          return <img src={oknoc} alt='oknoc'/>
                        default:
                          return 
                      }
                  })()}
                  <Box
                  className='inlinesl'
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                  >
                    <TextField 
                      id="standard-basic" 
                      value={props.odlegloscOknaOdKrawedzi} 
                      onChange={onOdlegloscOknaOdKrawedziValueChange} 
                      type='number' 
                      label='Odległość [cm]' 
                      variant="standard" 
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                    />
                  </Box>
                </React.Fragment>
              )
            }
        })()}   
      </Box>  
    </React.Fragment>
  )  
}
import Navbar from "./Components/Navbar";
import React, { useState } from "react";
import { Container } from "@mui/material"
import './App.css';
import StepperForm from "./Components/StepperForm";
import '@fontsource/roboto/400.css';
import axios from 'axios';


export default function App() {
  const API_URL = "http://kuchnie.stolarz.usermd.net/api/kitchenconfigs/";
  //const API_URL = "http://localhost:8000/api/kitchenconfigs/";
  const [dlugoscGorna, setDlugoscGorna] = useState(0);
  const [dlugoscDolna, setDlugoscDolna] = useState(0);
  const [dlugoscPrawa, setDlugoscPrawa] = useState(0);
  const [dlugoscLewa, setDlugoscLewa] = useState(0);
  const [wysokoscPomieszczenia, setWysokoscPomieszczenia] = useState(0);
  const [ustawienieMebli, setUstawienieMebli] = useState(0);
  const [scianaUstawienie, setScianaUstawienie] = useState(0);
  const [ifOkno, setIfOkno] = useState(0);
  const [wysokoscOknaOdPodlogiDoParapetu, setWysokoscOknaOdPodlogiDoParapetu] = useState(0);
  const [szerokoscOkna, setSzerokoscOkna] = useState(0);
  const [wysokoscOkna, setWysokoscOkna] = useState(0);
  const [lokalizacjaOkna, setLokalizacjaOkna] = useState(0);
  const [odlegloscOknaOdKrawedzi, setOdlegloscOknaOdKrawedzi] = useState(0);
  const [wodaUzytkowaSciana, setWodaUzytkowaSciana] = useState(0);
  const [wodaUzytkowaOdleglosc, setWodaUzytkowaOdleglosc] = useState(0);
  const [wodaUzytkowaWysokosc, setWodaUzytkowaWysokosc] = useState(0);
  const [przylaczeKanalizacyjneSciana, setPrzylaczeKanalizacyjneSciana] = useState(0);
  const [przylaczeKanalizacyjneOdleglosc, setPrzylaczeKanalizacyjneOdleglosc] = useState(0);
  const [przylaczeKanalizacyjneWysokosc, setPrzylaczeKanalizacyjneWysokosc] = useState(0);
  const [gazSciana, setGazSciana] = useState(0);
  const [gazOdleglosc, setGazOdleglosc] = useState(0);
  const [gazWysokosc, setGazWysokosc] = useState(0);
  const [okapRodzaj, setOkapRodzaj] = useState(0);
  const [okapSciana, setOkapSciana] = useState(0);
  const [okapOdleglosc, setOkapOdleglosc] = useState(0);
  const [okapWysokosc, setOkapWysokosc] = useState(0);
  const [lodowkaD, setLodowkaD] = useState(0);
  const [lodowkaW, setLodowkaW] = useState(0);
  const [lodowkaS, setLodowkaS] = useState(0);
  const [zmywarkaD, setZmywarkaD] = useState(0);
  const [zmywarkaW, setZmywarkaW] = useState(0);
  const [zmywarkaS, setZmywarkaS] = useState(0);
  const [piekarnikBuildin, setPiekarnikBuildin] = useState(0);
  const [plytaD, setPlytaD] = useState(0);
  const [plytaS, setPlytaS] = useState(0);
  const [okapD, setOkapD] = useState(0);
  const [okapW, setOkapW] = useState(0);
  const [okapS, setOkapS] = useState(0);
  const [okapFreehang, setOkapFreehang] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [email, setEmail] = useState('');
  const [ifEmailSend, setIfEmailSend] = useState(0);
  const [disabledSend, setDisabledSend] = useState(false);
  const [kitchenModel, setKitchenModel] = useState([]);

  const handleDlugoscGornaChange = (dlugoscGorna) => {
    setDlugoscGorna(dlugoscGorna);
  };

  const handleDlugoscDolnaChange = (dlugoscDolna) => {
    setDlugoscDolna(dlugoscDolna);
  };

  const handleDlugoscPrawaChange = (dlugoscPrawa) => {
    setDlugoscPrawa(dlugoscPrawa);
  };

  const handleDlugoscLewaChange = (dlugoscLewa) => {
    setDlugoscLewa(dlugoscLewa);
  };

  const handleWysokoscPomieszczeniaChange = (wysokoscPomieszczenia) => {
    setWysokoscPomieszczenia(wysokoscPomieszczenia);
  };

  const handleUstawienieMebliChange = (ustawienieMebli) => {
    setUstawienieMebli(ustawienieMebli);
    setScianaUstawienie(0);
  };

  const handleScianaUstawienieChange = (scianaUstawienie) => {
    setScianaUstawienie(scianaUstawienie);
  };

  const handleIfOknoChange = (ifOkno) => {
    setIfOkno(ifOkno);
  };

  const handleWysokoscOknaOdPodlogiDoParapetuChange = (wysokoscOknaOdPodlogiDoParapetu) => {
    setWysokoscOknaOdPodlogiDoParapetu(wysokoscOknaOdPodlogiDoParapetu);
  }

  const handleSzerokoscOknaChange = (szerokoscOkna) => {
    setSzerokoscOkna(szerokoscOkna);
  }

  const handleWysokoscOknaChange = (wysokoscOkna) => {
    setWysokoscOkna(wysokoscOkna);
  }

  const handleLokalizacjaOknaChange = (lokalizacjaOkna) => {
    setLokalizacjaOkna(lokalizacjaOkna);
  }

  const handleOdlegloscOknaOdKrawedziChange = (odlegloscOknaOdKrawedzi) => {
    setOdlegloscOknaOdKrawedzi(odlegloscOknaOdKrawedzi);
  }

  const handleWodaUzytkowaScianaChange = (wodaUzytkowaSciana) => {
    setWodaUzytkowaSciana(wodaUzytkowaSciana);
  }

  const handleWodaUzytkowaOdlegloscChange = (wodaUzytkowaOdleglosc) => {
    setWodaUzytkowaOdleglosc(wodaUzytkowaOdleglosc);
  }

  const handleWodaUzytkowaWysokoscChange = (wodaUzytkowaWysokosc) => {
    setWodaUzytkowaWysokosc(wodaUzytkowaWysokosc);
  }

  const handlePrzylaczeKanalizacyjneScianaChange = (przylaczeKanalizacyjneSciana) => {
    setPrzylaczeKanalizacyjneSciana(przylaczeKanalizacyjneSciana);
  }

  const handlePrzylaczeKanalizacyjneOdlegloscChange = (przylaczeKanalizacyjneOdleglosc) => {
    setPrzylaczeKanalizacyjneOdleglosc(przylaczeKanalizacyjneOdleglosc);
  }

  const handlePrzylaczeKanalizacyjneWysokoscChange = (przylaczeKanalizacyjneWysokosc) => {
    setPrzylaczeKanalizacyjneWysokosc(przylaczeKanalizacyjneWysokosc);
  }

  const handleGazScianaChange = (gazSciana) => {
    setGazSciana(gazSciana);
  }

  const handleGazOdlegloscChange = (gazOdleglosc) => {
    setGazOdleglosc(gazOdleglosc);
  }

  const handleGazWysokoscChange = (gazWysokosc) => {
    setGazWysokosc(gazWysokosc);
  }

  const handleOkapRodzajChange = (okapRodzaj) => {
    setOkapRodzaj(okapRodzaj);
  }

  const handleOkapScianaChange = (okapSciana) => {
    setOkapSciana(okapSciana);
  }

  const handleOkapOdlegloscChange = (okapOdleglosc) => {
    setOkapOdleglosc(okapOdleglosc);
  }

  const handleOkapWysokoscChange = (okapWysokosc) => {
    setOkapWysokosc(okapWysokosc);
  }

  const handleLodowkaDChange = (lodowkaD) => {
    setLodowkaD(lodowkaD);
  }

  const handleLodowkaWChange = (lodowkaW) => {
    setLodowkaW(lodowkaW);
  }

  const handleLodowkaSChange = (lodowkaS) => {
    setLodowkaS(lodowkaS);
  }

  const handleZmywarkaDChange = (zmywarkaD) => {
    setZmywarkaD(zmywarkaD);
  }

  const handleZmywarkaWChange = (zmywarkaW) => {
    setZmywarkaW(zmywarkaW);
  }

  const handleZmywarkaSChange = (zmywarkaS) => {
    setZmywarkaS(zmywarkaS);
  }

  const handlePiekarnikBuildinChange = (piekarnikBuildin) => {
    setPiekarnikBuildin(piekarnikBuildin);
  }

  const handlePlytaDChange = (plytaD) => {
    setPlytaD(plytaD);
  }

  const handlePlytaSChange = (plytaS) => {
    setPlytaS(plytaS);
  }

  const handleOkapDChange = (okapD) => {
    setOkapD(okapD);
  }

  const handleOkapWChange = (okapW) => {
    setOkapW(okapW);
  }

  const handleOkapSChange = (okapS) => {
    setOkapS(okapS);
  }

  const handleOkapFreehangChange = (okapFreehang) => {
    setOkapFreehang(okapFreehang);
  }

  const handleAdditionalInfoChange = (additionalInfo) => {
    setAdditionalInfo(additionalInfo);
  }

  const handleEmailChange = (email) => {
    setEmail(email);
  }

  const handleIfEmailSend = (ifEmailSend) => {
    setIfEmailSend(ifEmailSend);
  }

  const handleDisabledSend = (disabledSend) => {
    setDisabledSend(disabledSend);
  }

  const handleKitchenModelChange = (kitchenModel) => {
    setKitchenModel(kitchenModel);
  }

  const kitchenconfig = {
    wall_up_length: Number(dlugoscGorna),
    wall_down_length: Number(dlugoscDolna),
    wall_right_length: Number(dlugoscPrawa),
    wall_left_length: Number(dlugoscLewa),
    wall_height: Number(wysokoscPomieszczenia),
    furniture_location: Number(ustawienieMebli),
    furniture_location_helper: Number(scianaUstawienie),
    is_window: Number(ifOkno),
    window_height_from_floor: Number(wysokoscOknaOdPodlogiDoParapetu),
    window_size_width: Number(szerokoscOkna),
    window_size_height: Number(wysokoscOkna),
    window_location_wall: Number(lokalizacjaOkna),
    window_location_distance: Number(odlegloscOknaOdKrawedzi),
    potable_water_wall: Number(wodaUzytkowaSciana),
    potable_water_distance: Number(wodaUzytkowaOdleglosc),
    potable_water_from_floor: Number(wodaUzytkowaWysokosc),
    sewerage_wall: Number(przylaczeKanalizacyjneSciana),
    sewerage_distance: Number(przylaczeKanalizacyjneOdleglosc),
    sewerage_from_floor: Number(przylaczeKanalizacyjneWysokosc),
    gas_wall: Number(gazSciana),
    gas_distance: Number(gazOdleglosc),
    gas_from_floor: Number(gazWysokosc),
    ventilation_cookerhood: Number(okapRodzaj),
    ventilation_cookerhood_wall: Number(okapSciana),
    ventilation_cookerhood_distance: Number(okapOdleglosc),
    ventilation_cookerhood_from_floor: Number(okapWysokosc),
    fridge_depth: Number(lodowkaD),
    fridge_height: Number(lodowkaW),
    fridge_width: Number(lodowkaS),
    washing_mashine_depth: Number(zmywarkaD),
    washing_mashine_height: Number(zmywarkaW),
    washing_mashine_width: Number(zmywarkaS),
    oven_buildin: Number(piekarnikBuildin),
    heating_plate_depth: Number(plytaD),
    heating_plate_width: Number(plytaS),
    cookerhood_depth: Number(okapD),
    cookerhood_height: Number(okapW),
    cookerhood_width: Number(okapS),
    cookerhood_freehanging: Number(okapFreehang),
    additional_info: additionalInfo,
    email: email,
    kitchen_model: kitchenModel,
  }

  const handlePost = (e) => {
    e.preventDefault();
    axios.post(API_URL, {kitchenconfig})
    .then((response) => {
      console.log(response);
      handleIfEmailSend(1);
      setDisabledSend(false);
    }, (error) => {
      console.log(error);
      handleIfEmailSend(2);
      setDisabledSend(false);
    });
  }

  return (
    <div className="App">
      <Navbar id='navbar'></Navbar>
      <Container id="maincontainer" maxWidth="xl" sx={{bgcolor: 'background.paper', mt:3}}>
        <div id="inputcontainer">
          <StepperForm
            onKitchenModelChange={handleKitchenModelChange}
            kitchenModel={kitchenModel}

            onDlugoscGornaChange={handleDlugoscGornaChange}
            onDlugoscDolnaChange={handleDlugoscDolnaChange}
            onDlugoscPrawaChange={handleDlugoscPrawaChange}
            onDlugoscLewaChange={handleDlugoscLewaChange}
            onWysokoscPomieszczeniaChange={handleWysokoscPomieszczeniaChange}
            dlugoscGorna={dlugoscGorna}
            dlugoscDolna={dlugoscDolna}
            dlugoscPrawa={dlugoscPrawa}
            dlugoscLewa={dlugoscLewa}
            wysokoscPomieszczenia={wysokoscPomieszczenia}

            onUstawienieMebliChange={handleUstawienieMebliChange}
            onScianaUstawienieChange={handleScianaUstawienieChange}
            ustawienieMebli={ustawienieMebli}
            scianaUstawienie={scianaUstawienie}

            onIfOknoChange={handleIfOknoChange}
            ifOkno={ifOkno}
            onWysokoscOknaOdPodlogiDoParapetuChange={handleWysokoscOknaOdPodlogiDoParapetuChange}
            onSzerokoscOknaChange={handleSzerokoscOknaChange}
            onWysokoscOknaChange={handleWysokoscOknaChange}
            onLokalizacjaOknaChange={handleLokalizacjaOknaChange}
            onOdlegloscOknaOdKrawedziChange={handleOdlegloscOknaOdKrawedziChange}
            wysokoscOknaOdPodlogiDoParapetu={wysokoscOknaOdPodlogiDoParapetu}
            szerokoscOkna={szerokoscOkna}
            wysokoscOkna={wysokoscOkna}
            lokalizacjaOkna={lokalizacjaOkna}
            odlegloscOknaOdKrawedzi={odlegloscOknaOdKrawedzi}

            onWodaUzytkowaScianaChange={handleWodaUzytkowaScianaChange}
            onWodaUzytkowaOdlegloscChange={handleWodaUzytkowaOdlegloscChange}
            onWodaUzytkowaWysokoscChange={handleWodaUzytkowaWysokoscChange}
            onPrzylaczeKanalizacyjneScianaChange={handlePrzylaczeKanalizacyjneScianaChange}
            onPrzylaczeKanalizacyjneOdlegloscChange={handlePrzylaczeKanalizacyjneOdlegloscChange}
            onPrzylaczeKanalizacyjneWysokoscChange={handlePrzylaczeKanalizacyjneWysokoscChange}
            onGazScianaChange={handleGazScianaChange}
            onGazOdlegloscChange={handleGazOdlegloscChange}
            onGazWysokoscChange={handleGazWysokoscChange}
            onOkapRodzajChange={handleOkapRodzajChange}
            onOkapScianaChange={handleOkapScianaChange}
            onOkapOdlegloscChange={handleOkapOdlegloscChange}
            onOkapWysokoscChange={handleOkapWysokoscChange}
            wodaUzytkowaSciana={wodaUzytkowaSciana}
            wodaUzytkowaOdleglosc={wodaUzytkowaOdleglosc}
            wodaUzytkowaWysokosc={wodaUzytkowaWysokosc}
            przylaczeKanalizacyjneSciana={przylaczeKanalizacyjneSciana}
            przylaczeKanalizacyjneOdleglosc={przylaczeKanalizacyjneOdleglosc}
            przylaczeKanalizacyjneWysokosc={przylaczeKanalizacyjneWysokosc}
            gazSciana={gazSciana}
            gazOdleglosc={gazOdleglosc}
            gazWysokosc={gazWysokosc}
            okapRodzaj={okapRodzaj}
            okapSciana={okapSciana}
            okapOdleglosc={okapOdleglosc}
            okapWysokosc={okapWysokosc}

            onLodowkaDChange={handleLodowkaDChange}
            onLodowkaWChange={handleLodowkaWChange}
            onLodowkaSChange={handleLodowkaSChange}
            onZmywarkaDChange={handleZmywarkaDChange}
            onZmywarkaWChange={handleZmywarkaWChange}
            onZmywarkaSChange={handleZmywarkaSChange}
            onPiekarnikBuildinChange={handlePiekarnikBuildinChange}
            onPlytaDChange={handlePlytaDChange}
            onPlytaSChange={handlePlytaSChange}
            onOkapDChange={handleOkapDChange}
            onOkapWChange={handleOkapWChange}
            onOkapSChange={handleOkapSChange}
            onOkapFreehangChange={handleOkapFreehangChange}
            lodowkaD={lodowkaD}
            lodowkaW={lodowkaW}
            lodowkaS={lodowkaS}
            zmywarkaD={zmywarkaD}
            zmywarkaW={zmywarkaW}
            zmywarkaS={zmywarkaS}
            piekarnikBuildin={piekarnikBuildin}
            plytaD={plytaD}
            plytaS={plytaS}
            okapD={okapD}
            okapW={okapW}
            okapS={okapS}
            okapFreehang={okapFreehang}

            onAdditionalInfoChange={handleAdditionalInfoChange}
            additionalInfo={additionalInfo}

            onEmailChange={handleEmailChange}
            email={email}
            handleIfEmailSend={handleIfEmailSend}
            ifEmailSend={ifEmailSend}
            onDisabledSend={handleDisabledSend}
            disabledSend={disabledSend}

            handlePost={handlePost}
          ></StepperForm>
        </div>
        <div id="outputcontainer">
          {/*<ThreeScene
            dlugoscGorna={dlugoscGorna}
            dlugoscDolna={dlugoscDolna}
            dlugoscPrawa={dlugoscPrawa}
            dlugoscLewa={dlugoscLewa}
            wysokoscPomieszczenia={wysokoscPomieszczenia}
            ustawienieMebli={ustawienieMebli}
          ></ThreeScene>*/}
        </div>
      </Container>
    </div>
  );
}

import React from "react";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";

export default function NumberInput(props){
  const handleChange = (e) => {
    props.onValueChange(e.target.value);
  }

  return(
    <Box sx={{ display: 'flex', alignItems: 'end' }} className='outerboxsl'>
      <Box
        className='inlinesl'
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField 
          id="standard-basic" 
          value={props.value} 
          onChange={handleChange} 
          type='number' 
          label={props.placeholdersl} 
          variant="standard" 
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
      </Box>
    </Box>
  );
};
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import wzorscian from '../Images/wzorscian.jpeg';

export default function UstawienieMebli(props) {
  const onValueChange = (e) => {
    props.onUstawienieMebliChange(e.target.value);
  };
  const onScianaValueChange = (e) => {
    props.onScianaUstawienieChange(e.target.value);
  };

  return(
    <React.Fragment>
    <br/>
    <Box sx={{ minWidth: 120, maxWidth:300 }}>
      <FormControl fullWidth>
        <InputLabel id="select-ustawienie">Ustawienie</InputLabel>
          <Select
            id="select-ustawienie"
            value={props.ustawienieMebli}
            label="Ustawienie"
            onChange={onValueChange}
          >
            <MenuItem value={0}></MenuItem>
            <MenuItem value={1}>Narożne</MenuItem>
            <MenuItem value={2}>Na jedną ścianę</MenuItem>
            <MenuItem value={3}>Na ścianach równoległych</MenuItem>
          </Select>
      </FormControl>  
    </Box>
    <br />
    <Box sx={{ minWidth: 120, maxWidth:300 }}>
      {(() => {
        if (props.ustawienieMebli===1) {
          return (
            <React.Fragment>
            <img src={wzorscian} alt='sciany'/>
              <br/>
              <br/>
            <FormControl fullWidth>
              <InputLabel id="select-sciana-narozna-label">Ściany</InputLabel>
                <Select
                  id="select-sciana-narozna"
                  value={props.scianaUstawienie}
                  label="Ściana"
                  onChange={onScianaValueChange}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={7}>A i B</MenuItem>
                  <MenuItem value={8}>A i D</MenuItem>
                  <MenuItem value={9}>C i B</MenuItem>
                  <MenuItem value={10}>C i D</MenuItem>
                </Select>
            </FormControl> 
            </React.Fragment>
          )
        } else if (props.ustawienieMebli===2) {
          return (
            <React.Fragment>
            <img src={wzorscian} alt='sciany'/>
              <br/>
              <br/>
            <FormControl fullWidth>
              <InputLabel id="select-sciana-pojedyncza-label">Ściana</InputLabel>
                <Select
                  id="select-sciana-pojedyncza"
                  value={props.scianaUstawienie}
                  label="Ustawienie"
                  onChange={onScianaValueChange}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={3}>A</MenuItem>
                  <MenuItem value={2}>B</MenuItem>
                  <MenuItem value={4}>C</MenuItem>
                  <MenuItem value={1}>D</MenuItem>
                </Select>
            </FormControl> 
            </React.Fragment>
          )
        } else if (props.ustawienieMebli===3) {
          return (
            <React.Fragment>
            <img src={wzorscian} alt='sciany'/>
              <br/>
              <br/>
            <FormControl fullWidth>
              <InputLabel id="select-sciana-rownolegle-label">Ściany</InputLabel>
                <Select
                  id="select-sciana-rownolegle"
                  value={props.scianaUstawienie}
                  label="Ustawienie"
                  onChange={onScianaValueChange}
                >
                  <MenuItem value={0}></MenuItem>
                  <MenuItem value={5}>B i D</MenuItem>
                  <MenuItem value={6}>A i C</MenuItem>
                </Select>
            </FormControl> 
            </React.Fragment>
          )
        }
      })()}
    </Box>
    </React.Fragment>
  )  
}
import React from 'react';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function UwagiDodatkowe(props) {
  const handleChange = (e) => {
    props.onAdditionalInfoChange(e.target.value);
  };

  return(
    <React.Fragment>
      <Typography variant="body2" component={'span'}>
        Dodaj uwagi dodatkowe.
      </Typography>
      <br/>
      <br/>
      <TextField
          id="outlined-multiline-static"
          label="Treść"
          multiline
          rows={6}
          onChange={handleChange}
          defaultValue={props.additionalInfo}
          sx={{minWidth:400}}
      />
    </React.Fragment>
  )  
}
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import WymiaryScian from './WymiaryScian';
import UstawienieMebli from './UstawienieMebli';
import LokalizacjaOkna from './LokalizacjaOkna';
import WymiarowanieMediow from './WymiarowanieMediow';
import SprzetAGD from './SprzetAGD';
import UwagiDodatkowe from './UwagiDodatkowe';
import SendIcon from '@mui/icons-material/Send';
import KitchenModelsList from './KitchenModelsList';
import { TextField, Grid, Alert } from '@mui/material';


const steps = [
  {
    label: 'Model kuchni i wymiary ścian',
  },
  {
    label: 'Ustawienie mebli',
  },
  {
    label: 'Lokalizacja okna',
  },
  {
    label: 'Wymiarowanie mediów',
  },
  {
    label: 'Sprzęt AGD',
  },
  {
    label: 'Uwagi dodatkowe',
  },
];


export default function VerticalLinearStepper(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [emailError, setEmailError] = useState('');
  const [emailErrorBool, setEmailErrorBool] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    props.onDisabledSend(false);
    setEmailError('');
    setEmailErrorBool(false);
    props.handleIfEmailSend(0);
    props.onDlugoscGornaChange(0);
    props.onDlugoscDolnaChange(0);
    props.onDlugoscPrawaChange(0);
    props.onDlugoscLewaChange(0);
    props.onWysokoscPomieszczeniaChange(0);
    props.onUstawienieMebliChange(0);
    props.onScianaUstawienieChange(0);
    props.onIfOknoChange(0);
    props.onSzerokoscOknaChange(0);
    props.onWysokoscOknaChange(0);
    props.onLokalizacjaOknaChange(0);
    props.onOdlegloscOknaOdKrawedziChange(0);
    props.onWodaUzytkowaScianaChange(0);
    props.onWodaUzytkowaOdlegloscChange(0);
    props.onWodaUzytkowaWysokoscChange(0);
    props.onPrzylaczeKanalizacyjneScianaChange(0);
    props.onPrzylaczeKanalizacyjneOdlegloscChange(0);
    props.onPrzylaczeKanalizacyjneWysokoscChange(0);
    props.onGazScianaChange(0);
    props.onGazOdlegloscChange(0);
    props.onGazWysokoscChange(0);
    props.onOkapRodzajChange(0);
    props.onOkapScianaChange(0);
    props.onOkapOdlegloscChange(0);
    props.onOkapWysokoscChange(0);
    props.onLodowkaDChange(0);
    props.onLodowkaWChange(0);
    props.onLodowkaSChange(0);
    props.onZmywarkaDChange(0);
    props.onZmywarkaWChange(0);
    props.onZmywarkaSChange(0);
    props.onPiekarnikBuildinChange(0);
    props.onPlytaDChange(0);
    props.onPlytaSChange(0);
    props.onOkapDChange(0);
    props.onOkapWChange(0);
    props.onOkapSChange(0);
    props.onOkapFreehangChange(0);
    props.onAdditionalInfoChange('');
    props.onEmailChange('');
  };

  const handleEmailChange = (e) => {
    props.onEmailChange(e.target.value);
    if (!e.target.value) {
      setEmailError('To pole jest wymagane!')
    }
    if (e.target.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      setEmailError('Niepoprawny adres e-mail!')
      setEmailErrorBool(true)
    }
    else {
      setEmailErrorBool(false)
      setEmailError('')
    }
  };

  const handlePostInner = (e) => {
    if (emailErrorBool === false && props.email !== '') {
      props.onDisabledSend(true);
      if (!props.email) {
        setEmailError('To pole jest wymagane!')
      }
      if (props.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(props.email)) {
        setEmailError('Niepoprawny adres e-mail!')
        setEmailErrorBool(true)
      }
      else {
        setEmailErrorBool(false)
        setEmailError('')
        props.handlePost(e);
      }
    }
    else if (props.email === '' ) {
      setEmailError('To pole jest wymagane!')
      setEmailErrorBool(true);
      props.handleIfEmailSend(0);
    }
  }

  function getStepContent(step) { 
    switch (step) { 
      case 0: 
        return ( 
          <React.Fragment>
            <KitchenModelsList
              onKitchenModelChange={props.onKitchenModelChange}
              kitchenModel={props.kitchenModel}
            />
            <WymiaryScian 
              onDlugoscGornaChange={props.onDlugoscGornaChange}
              onDlugoscDolnaChange={props.onDlugoscDolnaChange}
              onDlugoscPrawaChange={props.onDlugoscPrawaChange}
              onDlugoscLewaChange={props.onDlugoscLewaChange}
              onWysokoscPomieszczeniaChange={props.onWysokoscPomieszczeniaChange}
              dlugoscGorna={props.dlugoscGorna}
              dlugoscDolna={props.dlugoscDolna}
              dlugoscPrawa={props.dlugoscPrawa}
              dlugoscLewa={props.dlugoscLewa}
              wysokoscPomieszczenia={props.wysokoscPomieszczenia}
            />
          </React.Fragment>
        ); 
  
      case 1: 
        return ( 
          <UstawienieMebli 
            onUstawienieMebliChange={props.onUstawienieMebliChange}
            onScianaUstawienieChange={props.onScianaUstawienieChange}
            ustawienieMebli={props.ustawienieMebli}        
            scianaUstawienie={props.scianaUstawienie}
          />
        ); 
  
      case 2: 
        return ( 
          <LokalizacjaOkna 
            onIfOknoChange={props.onIfOknoChange}
            onWysokoscOknaOdPodlogiDoParapetuChange={props.onWysokoscOknaOdPodlogiDoParapetuChange}
            onSzerokoscOknaChange={props.onSzerokoscOknaChange}
            onWysokoscOknaChange={props.onWysokoscOknaChange}
            onLokalizacjaOknaChange={props.onLokalizacjaOknaChange}
            onOdlegloscOknaOdKrawedziChange={props.onOdlegloscOknaOdKrawedziChange}
            ifOkno={props.ifOkno}
            wysokoscOknaOdPodlogiDoParapetu={props.wysokoscOknaOdPodlogiDoParapetu}
            szerokoscOkna={props.szerokoscOkna}
            wysokoscOkna={props.wysokoscOkna}
            lokalizacjaOkna={props.lokalizacjaOkna}
            odlegloscOknaOdKrawedzi={props.odlegloscOknaOdKrawedzi}
          />
        ); 
      
      case 3: 
        return ( 
          <WymiarowanieMediow
            onWodaUzytkowaScianaChange={props.onWodaUzytkowaScianaChange}
            onWodaUzytkowaOdlegloscChange={props.onWodaUzytkowaOdlegloscChange}
            onWodaUzytkowaWysokoscChange={props.onWodaUzytkowaWysokoscChange}
            onPrzylaczeKanalizacyjneScianaChange={props.onPrzylaczeKanalizacyjneScianaChange}
            onPrzylaczeKanalizacyjneOdlegloscChange={props.onPrzylaczeKanalizacyjneOdlegloscChange}
            onPrzylaczeKanalizacyjneWysokoscChange={props.onPrzylaczeKanalizacyjneWysokoscChange}
            onGazScianaChange={props.onGazScianaChange}
            onGazOdlegloscChange={props.onGazOdlegloscChange}
            onGazWysokoscChange={props.onGazWysokoscChange}
            onOkapRodzajChange={props.onOkapRodzajChange}
            onOkapScianaChange={props.onOkapScianaChange}
            onOkapOdlegloscChange={props.onOkapOdlegloscChange}
            onOkapWysokoscChange={props.onOkapWysokoscChange}
            wodaUzytkowaSciana={props.wodaUzytkowaSciana}
            wodaUzytkowaOdleglosc={props.wodaUzytkowaOdleglosc}
            wodaUzytkowaWysokosc={props.wodaUzytkowaWysokosc}
            przylaczeKanalizacyjneSciana={props.przylaczeKanalizacyjneSciana}
            przylaczeKanalizacyjneOdleglosc={props.przylaczeKanalizacyjneOdleglosc}
            przylaczeKanalizacyjneWysokosc={props.przylaczeKanalizacyjneWysokosc}
            gazSciana={props.gazSciana}
            gazOdleglosc={props.gazOdleglosc}
            gazWysokosc={props.gazWysokosc}
            okapRodzaj={props.okapRodzaj}
            okapSciana={props.okapSciana}
            okapOdleglosc={props.okapOdleglosc}
            okapWysokosc={props.okapWysokosc}
          />
        );
  
      case 4: 
        return ( 
          <SprzetAGD 
            onLodowkaDChange={props.onLodowkaDChange}
            onLodowkaWChange={props.onLodowkaWChange}
            onLodowkaSChange={props.onLodowkaSChange}
            onZmywarkaDChange={props.onZmywarkaDChange}
            onZmywarkaWChange={props.onZmywarkaWChange}
            onZmywarkaSChange={props.onZmywarkaSChange}
            onPiekarnikDChange={props.onPiekarnikDChange}
            onPiekarnikWChange={props.onPiekarnikWChange}
            onPiekarnikSChange={props.onPiekarnikSChange}
            onPiekarnikBuildinChange={props.onPiekarnikBuildinChange}
            onPlytaDChange={props.onPlytaDChange}
            onPlytaWChange={props.onPlytaWChange}
            onPlytaSChange={props.onPlytaSChange}
            onOkapDChange={props.onOkapDChange}
            onOkapWChange={props.onOkapWChange}
            onOkapSChange={props.onOkapSChange}
            onOkapFreehangChange={props.onOkapFreehangChange}
            lodowkaD={props.lodowkaD}
            lodowkaW={props.lodowkaW}
            lodowkaS={props.lodowkaS}
            zmywarkaD={props.zmywarkaD}
            zmywarkaW={props.zmywarkaW}
            zmywarkaS={props.zmywarkaS}
            piekarnikBuildin={props.piekarnikBuildin}
            plytaD={props.plytaD}
            plytaS={props.plytaS}
            okapD={props.okapD}
            okapW={props.okapW}
            okapS={props.okapS}
            okapFreehang={props.okapFreehang}
          />
        );

      case 5:
        return (
          <UwagiDodatkowe
            onAdditionalInfoChange={props.onAdditionalInfoChange}
            additionalInfo={props.additionalInfo}
          />
        )  
  
      default: 
        return 'Unknown step'; 
    } 
  } 

  return (
    <Box sx={{ maxWidth: 740 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 5 ? (
                  <Typography variant="caption" component={'span'}>Ostatni krok</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography component={'span'}>{getStepContent(index)}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Zakończ' : 'Kontynuuj'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Cofnij
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Wszystkie kroki zakończone, zresetuj do punktu pierwszego lub podaj e-mail i wyślij.</Typography>
          <TextField
            variant='standard'
            value={props.email}
            onChange={handleEmailChange}
            label='E-mail'
            helperText={emailError}
            error={emailErrorBool}
          />
          <br/><br/>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={8}>
              {(() => {
                <Typography>Sprawdzam!</Typography>
                if (props.ifEmailSend===1) {
                  return(
                    <Alert severity="success">Formularz wysłany prawidłowo na adres sklep@stolarz-lempert.pl</Alert>
                  )
                }
                else if (props.ifEmailSend===2) {
                  return(
                    <Alert severity="error">Błąd wysyłania formularza!</Alert>
                  )
                }
                else if (emailErrorBool) {
                  return(
                    <Alert severity="error">Sprawdź błędy formularza!</Alert>
                  )
                }
              })()}
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Cofnij
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 5 }}>
                Reset
              </Button>
              <Button onClick={handlePostInner} disabled={props.disabledSend} variant="contained" endIcon={<SendIcon />} sx={{ mt: 1, mr: 10 }}>
                Wyślij
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Switch from '@mui/material/Switch';
import { TextField, MenuItem, Select, InputLabel, FormControl, Grid, FormControlLabel } from "@mui/material";

export default function SwitchTextWymiarowanie(props){
  const [disabled, setDisabled] = useState(true);

  const handleSwitch = (prevState) => {
    setDisabled(!prevState);
  }

  const handleChange = (e) => {
    props.onValueChange(e.target.value);
  }

  const handleScianaChange = (e) => {
    props.onScianaChange(e.target.value);
  }

  const handleWysokoscChange = (e) => {
    props.onWysokoscChange(e.target.value);
  }

  useEffect(() => {
    if (props.value > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [props.value]);

  return(
    <Box sx={{ alignItems: 'end' }} className='outerboxsl'>
      <FormControlLabel
          control={
            <Switch className='inlinesl' checked={!disabled} onChange={() => handleSwitch(disabled, props.value)}/>
          }
          label={props.captionsl}
          sx={{ml:0}}
      />
      <br/>
      {(()=>{
        if(!disabled){
          return(
            <React.Fragment>
            <Grid container direction={'row'}>
            <FormControl sx={{ m: 1, minWidth: 150, maxWidth:200 }}>
              <InputLabel id="demo-simple-select-label">Sciana</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.sciana}
                label="Sciana"
                onChange={handleScianaChange}
              >
                <MenuItem value={0}></MenuItem>
                <MenuItem value={3}>A</MenuItem>
                <MenuItem value={2}>B</MenuItem>
                <MenuItem value={4}>C</MenuItem>
                <MenuItem value={1}>D</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid container direction={'row'}>
            <Box
              className='inlinesl'
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '17ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                id="standard-basic" 
                value={props.value} 
                onChange={handleChange} 
                type='number' 
                label={props.placeholdersl} 
                variant="standard" 
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Box>
            <Box
              className='inlinesl'
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '17ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField 
                id="standard-basic" 
                value={props.wysokosc} 
                onChange={handleWysokoscChange} 
                type='number' 
                label='Wysokość od podłogi [cm]'
                variant="standard" 
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Box>
            </Grid>
            </React.Fragment>
          )
        }
      })()}
    </Box>
  );
};
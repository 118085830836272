import React from 'react';
import NumberInput from './NumberInput';
import { Typography } from '@mui/material';
import wzorscian from '../Images/wzorscian.jpeg';

export default function WymiaryScian(props) {

  return(
    <React.Fragment>
      <NumberInput 
        placeholdersl='Wysokość pomieszczenia [cm]' 
        onValueChange={props.onWysokoscPomieszczeniaChange} 
        value={props.wysokoscPomieszczenia}
      />  
      {(()=> {
        if (props.wysokoscPomieszczenia>0) {
          return(
            <React.Fragment>
            <br/>  
            <Typography variant="body2" component={'span'}>
              Podaj wymiary istotnych ścian według poniższego schematu:
            </Typography>  
            <br/>
            <img src={wzorscian} alt='sciany'/>
            <br/>
            <br/>
            <NumberInput 
              placeholdersl='Długość ściany A [cm]' 
              onValueChange={props.onDlugoscGornaChange} 
              value={props.dlugoscGorna}
            />
            <NumberInput 
              placeholdersl='Długość ściany C [cm]' 
              onValueChange={props.onDlugoscDolnaChange}
              value={props.dlugoscDolna}
            />
            <NumberInput 
              placeholdersl='Długość ściany B [cm]' 
              onValueChange={props.onDlugoscPrawaChange}
              value={props.dlugoscPrawa}
            />
            <NumberInput 
              placeholdersl='Długość ściany D [cm]' 
              onValueChange={props.onDlugoscLewaChange}
              value={props.dlugoscLewa}
            />  
            </React.Fragment>
          )
        }
      })()}
      <br/>
    </React.Fragment>
  )  
}